import React, { FC, useRef, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useLocalState } from "../../../graphql/hooks";
import { DragAndDrop } from "../../editable/DragAndDrop";
import { useApolloClient, useMutation } from "@apollo/client";
import { ADITOR_TEMPLATE_UPLOAD } from "../../../graphql/mutations";
import {
    AditorTemplateMutationData,
    AditorTemplateMutationVariables
} from "../../../models/aditor";
import "../Modals.scss";

interface Props {
    show: boolean;
    onClose: () => void;
}

export const AditorTemplateUploadModal: FC<Props> = ({ show, onClose }) => {
    const client = useApolloClient();
    const inputFileElement = useRef<HTMLInputElement | null>(null);
    const [info, updateInfo] = useState("");
    const [files, updateFiles] = useState<File[]>([]);
    const [fileNames, updateFileNames] = useState<string[]>([]);
    const [submitting, updateSubmitting] = useState(false);
    const { useDarkMode } = useLocalState();

    const [aditorTemplateUpload] = useMutation<
        AditorTemplateMutationData,
        AditorTemplateMutationVariables
    >(ADITOR_TEMPLATE_UPLOAD);

    const addFile = (newFiles: File[]) => {
        if (newFiles.length === 1) {
            updateFiles(newFiles);
            updateFileNames([newFiles[0].name]);
        } else {
            updateInfo("Only one template can be imported at a time!");
            setTimeout(() => {
                updateInfo("");
            }, 2000);
            updateSubmitting(false);
        }
    };

    const removeFile = (name: string) => {
        const currentFiles = files.filter(file => file.name !== name);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const submit = async () => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].type !== "application/zip") {
                updateInfo("Unsupported file type!");
                setTimeout(() => {
                    updateInfo("");
                }, 2000);
                updateSubmitting(false);
                return;
            }
        }
        try {
            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const base64 = e.target.result.split(",")[1]; // Extract Base64 string after the prefix
                    console.log("[DEBUG] base64 ", base64);
                    aditorTemplateUpload({
                        variables: {
                            templateKey: fileNames[index].trim(),
                            data: base64
                        }
                    })
                        .then(_ => {
                            client.refetchQueries({
                                include: [
                                    "aditorTemplates",
                                    "aditorTemplateUrls"
                                ]
                            });
                        })
                        .catch(error => {
                            console.log(
                                "[DEBUG] aditorTemplateUpload failed with error ",
                                error
                            );
                        });
                };
                reader.readAsDataURL(files[index]);
            }
            updateFiles([]);
            updateFileNames([]);
            onClose();
        } catch (error) {
            console.log("[DEBUG] submit error ", error);
            // @ts-ignore
            updateInfo(error.message);
            setTimeout(() => {
                updateInfo("");
            }, 4000);
        }

        updateSubmitting(false);
    };

    const onFileLoadClick = () => {
        // @ts-ignore
        inputFileElement.current.click();
    };
    const onChangeFile = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const newFiles = event.target.files;

        if (newFiles.length > 0) {
            for (let i = 0; i < newFiles.length; i++) {
                const element = newFiles[i];
                if (element.type !== "application/zip") {
                    updateInfo("Unsupported file type!");
                    setTimeout(() => {
                        updateInfo("");
                    }, 2000);
                    return;
                }
            }
        }

        if (newFiles.length === 1) {
            updateFiles(newFiles);
            updateFileNames([newFiles[0].name]);
        } else {
            updateInfo("Only one template can be imported at a time!");
            setTimeout(() => {
                updateInfo("");
            }, 2000);
            updateSubmitting(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Col>
                    <h4>Upload template</h4>
                </Col>
                <Col></Col>
            </Modal.Header>
            <Modal.Body className="modal-body p-2">
                <DragAndDrop
                    instructions={
                        <span>
                            You can upload template by{" "}
                            <span className="text-info">dragging it here</span>{" "}
                            or by using the upload file button below. Only .zip
                            files are currently supported.
                        </span>
                    }
                    fileNames={fileNames}
                    addFiles={addFile}
                    removeFile={removeFile}
                />
                <input
                    type="file"
                    id="file"
                    ref={inputFileElement}
                    style={{ display: "none" }}
                    onChange={onChangeFile}
                />
                <span className="d-flex justify-content-center">
                    <Button className="btn-info" onClick={onFileLoadClick}>
                        Upload File
                    </Button>
                </span>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column justify-content-center align-content-center">
                <h5 className="text-center text-danger">{info}</h5>
                <Button
                    disabled={submitting}
                    onClick={() => {
                        updateSubmitting(true);
                        submit();
                    }}
                >
                    {submitting ? "Submitting..." : "Submit"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { AditorTemplateUrlsData } from "../../../models/aditor";
import { ADITOR_GET_TEMPLATE_URLS } from "../../../graphql/queries";
import { Button, Card, Container } from "react-bootstrap";
import { AditorTools } from "../../views/admin/AditorTools";
import { ProtectedComponent } from "../../../auth/ProtectedComponent";
import styles from "../admin/AdminPage.module.scss";

export const AditorTemplates = () => {
    const { error, data: { aditorTemplateUrls } = {} } =
        useQuery<AditorTemplateUrlsData>(ADITOR_GET_TEMPLATE_URLS);
    const isIframe = window.self !== window.top;

    useEffect(() => {
        if (isIframe && aditorTemplateUrls !== undefined) {
            console.log("[DEBUG] aditorTemplateUrls ", aditorTemplateUrls);
            window.parent.postMessage(
                {
                    type: "aditorTemplates",
                    data: { templates: aditorTemplateUrls }
                },
                "*" //"https://seepia-review.s3.eu-north-1.amazonaws.com"
            );
        }
    }, [aditorTemplateUrls]);

    useEffect(() => {
        if (error !== undefined) {
            window.parent.postMessage(
                { type: "aditorTemplates", data: { error: error } },
                "*" // "https://seepia-review.s3.eu-north-1.amazonaws.com"
            );
        }
    }, [error]);

    const downloadFile = (url: string) => {
        const win = window.open(url, "_blank");
        if (win !== null) {
            win.focus();
        }
    };

    return (
        <Container className={`${styles.responsiveContainer} text-center mt-5`}>
            <ProtectedComponent scopes={["admin"]}>
                <AditorTools />
            </ProtectedComponent>
            <h2 className="text-inverse">Aditor Templates</h2>
            {aditorTemplateUrls?.map((item, index) => (
                <Card
                    key={item.template + index.toString()}
                    className="text-inverse m-2"
                >
                    <Card.Header>{item.template}</Card.Header>
                    <Card.Body>
                        <Button
                            onClick={() => {
                                downloadFile(item.url);
                            }}
                        >
                            Download
                        </Button>
                    </Card.Body>
                </Card>
            ))}
        </Container>
    );
};

import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    Container,
    Nav,
    Row,
    Tab
} from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    RevisionConfigData,
    RevisionVariables
} from "../../models/types";
import { useLocalState, useRevision } from "../../graphql/hooks";
import {
    PARTIAL_UPDATE_REVISION,
    UPDATE_REVISION
} from "../../graphql/mutations";
import { Link, useLocation, useParams } from "react-router-dom";
import { VariationViewType } from "../../models/common";
import { NewItemModal } from "../modals/NewItemModal";
import { BackButton } from "../simple/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faDownload } from "@fortawesome/free-solid-svg-icons";
import { EditableTextComponent } from "../editable/EditableTextComponent";
import { Settings } from "../views/variations/Settings";
import { CurrentBuildsPanel } from "./CurrentBuildsPanel";
import { SimpleToast } from "../simple/SimpleToast";
import { BuildsManager } from "../views/variations/BuildsManager";
import { VariationsManager } from "../views/variations/VariationsManager";
import { LegacyLocalizations } from "../views/LegacyLocalizations";
import { LocalizationManagement } from "../views/LocalizationManagement";
import { NetworksManager } from "../views/variations/NetworksManager";
import { ReskinBlacklist } from "../views/variations/ReskinBlackList";
import { Placeholders } from "../views/variations/Placeholders";
import { AnalyticsSchemaDesignerView } from "../views/analytics/AnalyticsSchemaDesignerView";
import {
    updateEditPanelState,
    updateRevisionPartially
} from "../../common/Helpers";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { PathTemplateCheckOverlayBadge } from "../simple/PathTemplateCheckOverlayBadge";
import { DebugOverlayBadge } from "../simple/DebugOverlayBadge";
import { DefaultLocalizationMissingOverlayBadge } from "../simple/DefaultLocalizationMissingOverlayBadge";
import { LegacyLocalizationCheckOverlayBadge } from "../simple/LegacyLocalizationCheckOverlayBadge";
import { initialEditPanelState } from "../../graphql/client";
import { EditPanelBuilds } from "../views/EditPanelBuilds";
import { Configurator } from "../views/variations/Configurator";
import { GET_REVISION_CONFIG_CONFIGURATOR } from "../../graphql/queries";
import { TemplatorConfig } from "../views/variations/TemplatorConfig";
import { ClientBuildManager } from "../views/variations/ClientBuildManager";
import { SoftLimits } from "../views/softLimits/SoftLimits";
import { UpButton } from "../buttons/UpButton";
import { CompressionCheckOverlayBadge } from "../simple/CompressionCheckOverlayBadge";
import cx from "classnames";
import styles from "./EditPanel.module.scss";
import { MutatorConfigCheckOverlayBadge } from "../simple/MutatorConfigCheckOverlayBadge";

export const EditPanel = () => {
    const { revisionId } = useParams<{
        revisionId: string;
    }>();
    const location = useLocation();
    const client = useApolloClient();
    const { developerMode } = useLocalState();
    const revision = useRevision(Number(revisionId));
    const [isModalVisible, setModalVisibleState] = useState(false);
    const [variationView, updateVariationView] = useState(
        VariationViewType.Builds
    );
    const [currentTab, updateCurrentTab] = useState("versions");
    const [showHelp, setShowHelp] = useState(false);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const { data: { revisionConfig } = {} } = useQuery<
        RevisionConfigData,
        RevisionVariables
    >(GET_REVISION_CONFIG_CONFIGURATOR, {
        variables: { revisionId: Number(revisionId) }
    });

    useEffect(() => {
        return () => {
            updateEditPanelState(client, initialEditPanelState);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openNewItemModal = (type: VariationViewType) => {
        if (revision === undefined) {
            return;
        }
        updateVariationView(type);
        setModalVisibleState(true);
    };

    const saveItemFromModal = async (item: any) => {
        if (revision === undefined) {
            return;
        }

        switch (variationView as VariationViewType) {
            case VariationViewType.Builds:
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { builds: [item] },
                    "new"
                );
                break;
            case VariationViewType.Variations:
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { variations: [item] },
                    "new"
                );
                break;
            case VariationViewType.Networks:
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { networks: [item] },
                    "new"
                );
                break;
            case VariationViewType.Localizations:
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { localizations: [item] },
                    "new"
                );
                break;
            case VariationViewType.LegacyLocalizations:
                await updateRevisionPartially(
                    client,
                    revision,
                    partialUpdateRevision,
                    { legacyLocalizations: [item] },
                    "new"
                );
                break;
            default:
                return;
        }
    };

    if (!revisionId || !revision) {
        return null;
    }

    const aditorUrl = `${location.pathname}/aditor`;
    const mutatorUrl = `${location.pathname}/customator`;

    const toggleShowHelp = () => setShowHelp(!showHelp);

    return (
        <>
            <div className="d-flex">
                <div
                    className={cx("m-0 p-0 pt-2", {
                        "col-10": developerMode,
                        "col-12 editPanelClientView": !developerMode
                    })}
                >
                    <Tab.Container
                        id="variation-tabs"
                        defaultActiveKey="versions"
                    >
                        <Row className="p-2">
                            <Col className="col-2 mt-1">
                                <span title="Back to Products">
                                    <BackButton
                                        target={"/products"}
                                        size={"lg"}
                                    />
                                </span>
                            </Col>
                            <Col
                                className={cx(
                                    "d-flex justify-content-center col-8 text-inverse",
                                    {
                                        "mt-2": !developerMode
                                    }
                                )}
                            >
                                <div className={styles.minHeight}>
                                    <EditableTextComponent
                                        revisionId={revision.id}
                                        isEditable={true}
                                        text={
                                            revision.name ? revision.name : ""
                                        }
                                        mutation={UPDATE_REVISION}
                                        valueName="name"
                                        className={{
                                            text: "h3",
                                            container: "text-center"
                                        }}
                                    />
                                </div>
                            </Col>
                            {developerMode && currentTab === "versions" ? (
                                <Col className="d-flex justify-content-end col-2">
                                    <Button
                                        as={"a"}
                                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                            JSON.stringify(revision.buildData)
                                        )}`}
                                        download={"buildSets.json"}
                                        data-toggle="tooltip"
                                        title="Download buildSets.json"
                                        className={styles.downloadButton}
                                    >
                                        <FontAwesomeIcon
                                            icon={faDownload}
                                            className={styles.downloadIcon}
                                        />
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        <Row className="justify-content-center g-0">
                            <Col className="mb-3" xs="auto">
                                <DebugOverlayBadge
                                    debug={revision.buildData?.debug || false}
                                />
                            </Col>
                            <Col className="mb-3" xs="auto">
                                <CompressionCheckOverlayBadge />
                            </Col>
                            <Col className="mb-3" xs="auto">
                                <DefaultLocalizationMissingOverlayBadge />
                            </Col>
                            <Col className="mb-3" xs="auto">
                                <PathTemplateCheckOverlayBadge />
                            </Col>
                            <Col className="mb-3" xs="auto">
                                <LegacyLocalizationCheckOverlayBadge />
                            </Col>
                            <Col className="mb-3" xs="auto">
                                <MutatorConfigCheckOverlayBadge />
                            </Col>
                        </Row>
                        {developerMode && (
                            <Nav
                                className="justify-content-center"
                                variant="pills"
                                onSelect={eventKey =>
                                    updateCurrentTab(eventKey || "versions")
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        className={styles.navLink}
                                        eventKey="versions"
                                    >
                                        Versions
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className={styles.navLink}
                                        eventKey="settings"
                                    >
                                        Settings
                                    </Nav.Link>
                                </Nav.Item>
                                {developerMode &&
                                revisionConfig?.configurator ? (
                                    <Nav.Item>
                                        <Nav.Link
                                            className={styles.navLink}
                                            eventKey="configurator"
                                        >
                                            Configurator
                                        </Nav.Link>
                                    </Nav.Item>
                                ) : null}
                                {revisionConfig?.useTemplatorInPipeline &&
                                revisionConfig?.allowTemplatorConfigModification ? (
                                    <Nav.Item>
                                        <Nav.Link
                                            className={styles.navLink}
                                            eventKey="templatorConfig"
                                        >
                                            Templator Config
                                        </Nav.Link>
                                    </Nav.Item>
                                ) : null}
                                {revision.buildData?.analytics ? (
                                    <Nav.Item>
                                        <Nav.Link
                                            className={styles.navLink}
                                            eventKey="schema"
                                        >
                                            Schema
                                        </Nav.Link>
                                    </Nav.Item>
                                ) : null}
                                {revision.buildData?.useAditor ? (
                                    <Nav.Item>
                                        <Nav.Link
                                            className={styles.navLink}
                                            eventKey="aditor"
                                            as={Link}
                                            to={aditorUrl}
                                        >
                                            Aditor
                                        </Nav.Link>
                                    </Nav.Item>
                                ) : null}
                                {revision.buildData?.useMutator ? (
                                    <Nav.Item>
                                        <Nav.Link
                                            className={styles.navLink}
                                            eventKey="mutator"
                                            as={Link}
                                            to={mutatorUrl}
                                        >
                                            Mutator
                                        </Nav.Link>
                                    </Nav.Item>
                                ) : null}
                            </Nav>
                        )}
                        <Tab.Content className="pe-2">
                            <Tab.Pane eventKey="versions">
                                {developerMode ? (
                                    <BuildsManager
                                        revision={revision}
                                        openNewItemModal={openNewItemModal}
                                    />
                                ) : (
                                    <ClientBuildManager revision={revision} />
                                )}
                                <VariationsManager
                                    revision={revision}
                                    openNewItemModal={openNewItemModal}
                                />
                                {revision.buildData?.useLegacyLocalizations ? (
                                    <LegacyLocalizations
                                        revision={revision}
                                        developerMode={developerMode}
                                        openNewItemModal={openNewItemModal}
                                    />
                                ) : revision.id > 0 ? (
                                    <LocalizationManagement
                                        revision={revision}
                                        developerMode={developerMode}
                                    />
                                ) : null}
                                <NetworksManager
                                    revision={revision}
                                    developerMode={developerMode}
                                    openNewItemModal={openNewItemModal}
                                />
                                <ReskinBlacklist
                                    revision={revision}
                                    developerMode={developerMode}
                                />
                                <Placeholders
                                    revision={revision}
                                    developerMode={developerMode}
                                />
                                <SoftLimits
                                    revision={revision}
                                    developerMode={developerMode}
                                />

                                {!developerMode &&
                                    revision.buildData?.useMutator && (
                                        <Container className="mb-3 p-0">
                                            <Card>
                                                <Card.Header className="d-flex align-items-center">
                                                    <h5 className="text-inverse-50 mb-1 me-1">
                                                        Customator
                                                    </h5>
                                                    <div className="float-end ms-auto">
                                                        <Link
                                                            to={`${location.pathname}/customator`}
                                                        >
                                                            <Button
                                                                variant="primary"
                                                                title="Edit the playable ad build in Customator view"
                                                            >
                                                                Go to Customator
                                                            </Button>
                                                        </Link>
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={
                                                                toggleShowHelp
                                                            }
                                                            className={`mouseHover ms-3 ${
                                                                showHelp
                                                                    ? "text-inverse-50"
                                                                    : "text-inverse-25"
                                                            }`}
                                                            title={
                                                                showHelp
                                                                    ? "Hide Help"
                                                                    : "Show Help"
                                                            }
                                                        />
                                                    </div>
                                                </Card.Header>
                                                <Collapse in={showHelp}>
                                                    <div>
                                                        <Card.Footer className="bread-text no-border text-inverse-50 pe-1">
                                                            <p>
                                                                Customator tool
                                                                allows limited
                                                                editing of the
                                                                playable ad with
                                                                a visual user
                                                                interface.
                                                                Customator is
                                                                available if the
                                                                development has
                                                                set it up for
                                                                the project.
                                                            </p>
                                                        </Card.Footer>
                                                    </div>
                                                </Collapse>
                                            </Card>
                                        </Container>
                                    )}
                                {!developerMode && (
                                    <Container className="mb-2 p-0">
                                        <Settings
                                            revision={revision}
                                            developerMode={developerMode}
                                        />
                                    </Container>
                                )}
                                {revision && revision.buildData ? (
                                    <EditPanelBuilds revision={revision} />
                                ) : null}
                                <Row className="justify-content-center mb-3">
                                    <UpButton />
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                        {currentTab === "configurator" &&
                        revisionConfig?.configurator ? (
                            <Tab.Content className="pe-2">
                                <Tab.Pane eventKey="configurator">
                                    <Configurator
                                        developerMode={developerMode}
                                        revisionId={revisionId}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        ) : null}
                        {currentTab === "templatorConfig" &&
                        revisionConfig?.useTemplatorInPipeline &&
                        revisionConfig?.allowTemplatorConfigModification ? (
                            <Tab.Content className="pe-2">
                                <Tab.Pane eventKey="templatorConfig">
                                    <TemplatorConfig
                                        developerMode={developerMode}
                                        revisionId={revisionId}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        ) : null}
                        <Tab.Content className="pe-2">
                            <Tab.Pane eventKey="settings">
                                {currentTab === "settings" ? (
                                    <Settings
                                        revision={revision}
                                        developerMode={developerMode}
                                    />
                                ) : null}
                            </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content className="pe-2">
                            <Tab.Pane eventKey="schema">
                                {currentTab === "schema" ? (
                                    <AnalyticsSchemaDesignerView
                                        revisionId={Number(revision.id)}
                                        analyticsSchema={
                                            revision.analyticsConfig
                                        }
                                    />
                                ) : null}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    <NewItemModal
                        revision={revision}
                        show={isModalVisible}
                        type={variationView as VariationViewType}
                        onClose={() => {
                            setModalVisibleState(false);
                        }}
                        onSave={async (item: any) => {
                            await saveItemFromModal(item);
                            setModalVisibleState(false);
                        }}
                    />
                </div>
                <SimpleToast />
                {revision && revision.id !== -1 ? (
                    <CurrentBuildsPanel
                        revisionId={revision.id}
                        developerMode={developerMode}
                    />
                ) : null}
            </div>
        </>
    );
};

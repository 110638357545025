import { gql } from "@apollo/client";

/////////////////
// Local State //
/////////////////
export const GET_LOCAL_STATE = gql`
    {
        state @client
    }
`;

export const GET_LOCAL_ANALYTICS_STATE = gql`
    {
        analyticsState @client
    }
`;

export const GET_LOCAL_BUILD_STATE = gql`
    {
        buildsState @client
    }
`;

export const GET_LOCAL_NOTIFICATION_STATE = gql`
    {
        notificationState @client
    }
`;

export const GET_LOCAL_PAGINATOR_STATE = gql`
    {
        paginatorState @client
    }
`;

export const GET_LOCAL_EDITPANEL_STATE = gql`
    {
        editPanelState @client
    }
`;

export const GET_LOCAL_SORTING_STATE = gql`
    {
        sortingState @client
    }
`;

export const GET_LOCAL_MUTATOR_STATE = gql`
    {
        mutatorState @client
    }
`;

//////////
// User //
//////////
export const GET_ME = gql`
    query {
        me {
            id
            displayName
            email
            useDarkMode
            analyticsMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
        }
    }
`;

export const GET_USER = gql`
    query user($userId: ID!) {
        user(userId: $userId) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            products {
                id
                name
            }
            projects {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
            authType
        }
    }
`;

export const GET_USERS = gql`
    query users($organizationId: ID) {
        users(organizationId: $organizationId) {
            id
            displayName
            email
            useDarkMode
            organization {
                id
                name
            }
            groupList
            scopes
            analyticsFollowList
            authType
        }
    }
`;

export const GET_USER_ANALYTICS_FOLLOW_LIST = gql`
    query userFollowList {
        userFollowList {
            analyticsBuilds {
                id
                filename
                buildId
                batchId
            }
            legacyAnalyticsBuilds {
                id
                filename
                buildId
                legacyBuildSet {
                    batchId
                }
            }
        }
    }
`;

//////////////////
// Organization //
//////////////////
export const GET_ORGANIZATIONS = gql`
    query organizations {
        organizations {
            id
            name
            organizationType
            organizationIdentifier
        }
    }
`;

/////////////////////////
// Organization Config //
/////////////////////////
export const GET_ORGANIZATION_CONFIG = gql`
    query organizationConfig($organizationId: ID!) {
        organizationConfig(organizationId: $organizationId) {
            id
            softLimits
        }
    }
`;

/////////////
// Product //
/////////////
export const GET_PRODUCTS_BY_USER_ORGANIZATION = gql`
    query getProducts($searchTerm: String) {
        products(searchTerm: $searchTerm) {
            id
            name
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateUpdated
            organization {
                id
                name
                dateUpdated
            }
        }
    }
`;

export const GET_PRODUCTS = gql`
    query allProducts($organizationId: ID) {
        allProducts(organizationId: $organizationId) {
            id
            name
            organization {
                id
                name
            }
        }
    }
`;

export const GET_ANALYTICS_PRODUCTS = gql`
    query analyticsProducts($organizationId: ID!) {
        analyticsProducts(organizationId: $organizationId) {
            id
            name
            organization {
                id
            }
        }
    }
`;

export const GET_PRODUCT_META_DATA = gql`
    query productMetaData($productId: ID!) {
        productMetaData(productId: $productId) {
            id
            development
            review
            accepted
            rejected
            released
            bugged
            testAvailable
            testRequests
        }
    }
`;

/////////////
// Project //
/////////////
export const GET_PROJECTS_BY_PRODUCT = gql`
    query getProjects($productId: ID!, $searchTerm: String) {
        projects(productId: $productId, searchTerm: $searchTerm) {
            id
            name
            state
            gitProjectId
            gitProjectName
            gitProjectBranch
            slackChannel
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateUpdated
            product {
                id
                name
            }
        }
    }
`;

export const GET_PROJECTS = gql`
    query allProjects($organizationId: ID) {
        allProjects(organizationId: $organizationId) {
            id
            name
            state
            gitProjectId
            gitProjectName
            gitProjectBranch
            slackChannel
            dateUpdated
            product {
                id
                name
            }
        }
    }
`;

export const GET_ANALYTICS_PROJECTS = gql`
    query analyticsProjects($productId: ID!) {
        analyticsProjects(productId: $productId) {
            id
            name
            dateUpdated
            product {
                id
                name
            }
        }
    }
`;

export const GET_SIMPLE_PROJECTS = gql`
    query getProjects($productId: ID!) {
        projects(productId: $productId) {
            id
            name
            gitProjectId
            gitProjectName
            gitProjectBranch
            slackChannel
            dateUpdated
        }
    }
`;

export const GET_EMPTY_PROJECTS = gql`
    query getEmptyProjects {
        emptyProjects {
            id
            name
            gitProjectId
            gitProjectName
            gitProjectBranch
        }
    }
`;

export const GET_PROJECT_META_DATA = gql`
    query projectMetaData($projectId: ID!) {
        projectMetaData(projectId: $projectId) {
            id
            development
            review
            accepted
            rejected
            released
            bugged
            testAvailable
            testRequests
        }
    }
`;

////////////////////
// Project Config //
////////////////////
export const GET_PROJECT_CONFIG = gql`
    query projectConfig($projectId: ID!) {
        projectConfig(projectId: $projectId) {
            id
            softLimits
            analyticsNetworks
            zipNameTemplate
        }
    }
`;

//////////////
// Revision //
//////////////
export const GET_REVISIONS = gql`
    query getRevisions($projectId: ID!, $searchTerm: String) {
        revisions(projectId: $projectId, searchTerm: $searchTerm) {
            id
            name
            branch
            gitId
            releaseType
            analyticsConfig
            archived
            userImpressions
            userGameplay
            userEndScreen
            impressions
            gameplay
            endScreen
            dateCreated
            dateUpdated
            project {
                id
                name
                product {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_ANALYTICS_REVISIONS = gql`
    query analyticsRevisions($projectId: ID!) {
        analyticsRevisions(projectId: $projectId) {
            id
            name
            project {
                id
                product {
                    id
                }
            }
            dateCreated
            dateUpdated
        }
    }
`;

export const GET_REVISION = gql`
    query getRevision($revisionId: ID!) {
        revision(revisionId: $revisionId) {
            id
            name
            branch
            gitId
            buildData
            analyticsConfig
            releaseType
            dateCreated
            dateUpdated
            revisionConfig {
                id
                sets
                setBlacklist
                useTemplatorInPipeline
            }
            project {
                id
                name
                product {
                    id
                    name
                    organization {
                        id
                        name
                    }
                }
            }
        }
    }
`;

/////////////////////
// Revision Config //
/////////////////////
export const GET_REVISION_CONFIG_LOCALIZATIONS = gql`
    query getRevisionConfig($revisionId: ID!) {
        revisionConfig(revisionId: $revisionId) {
            id
            localizations
            setBlacklist
        }
    }
`;

export const GET_REVISION_CONFIG_CONFIGURATOR = gql`
    query getRevisionConfig($revisionId: ID!) {
        revisionConfig(revisionId: $revisionId) {
            id
            configurator
            templatorConfig
            sets
            setBlacklist
            useTemplatorInPipeline
            allowTemplatorConfigModification
        }
    }
`;

export const GET_REVISION_CONFIG = gql`
    query getRevisionConfig($revisionId: ID!) {
        revisionConfig(revisionId: $revisionId) {
            id
            configurator
            templatorConfig
            useTemplatorInPipeline
            allowTemplatorConfigModification
        }
    }
`;

export const GET_IS_VALID_LOCALIZATION_TAG = gql`
    query isValidLocalizationTag($localizationTag: String!) {
        isValidLocalizationTag(localizationTag: $localizationTag)
    }
`;

/////////////////////
// Revision Events //
/////////////////////
export const GET_REVISION_HISTORY = gql`
    query getEvents($revisionId: ID!) {
        revisionEvents(revisionId: $revisionId) {
            id
            user {
                id
                displayName
                email
            }
            build {
                id
            }
            description
            keys
            eventType
            dateCreated
        }
    }
`;

///////////
// Build //
///////////
export const GET_RESULT = gql`
    query getBuild($id: ID!) {
        build(id: $id) {
            id
            dateCreated
            dateUpdated
            state
            buildJobCount
            reportData
            analyticsSchema
            zipUrl
            legacyLinkUrl
            pathTemplate
            analyticsEnabled
            buildType
            revision {
                id
                name
                releaseType
                project {
                    id
                    name
                    product {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_RESULTS = gql`
    query getAllBuilds($revisionId: ID, $clientTest: Boolean) {
        builds(revisionId: $revisionId, clientTest: $clientTest) {
            id
            dateCreated
            state
            revision {
                id
                name
            }
            jobIds
            buildJobCount
            networkBuilds {
                id
                state
                arrayIndex
                errors
            }
            analyticsEnabled
            buildType
        }
    }
`;

export const GET_BATCH_BUILDS = gql`
    query getBatchBuilds($projectId: ID, $revisionId: ID) {
        builds(projectId: $projectId, revisionId: $revisionId) {
            id
            state
        }
    }
`;

export const GET_ANALYTICS_BATCH_BUILDS = gql`
    query analyticsBuilds($revisionId: ID!) {
        analyticsBuilds(revisionId: $revisionId) {
            id
            revision {
                id
                project {
                    id
                    product {
                        id
                    }
                }
            }
            analyticsSchema
            analyticsEnabled
            buildType
            dateCreated
            dateUpdated
        }
    }
`;

////////////////////
// Network Builds //
////////////////////
// export const GET_NETWORK_BUILDS = gql`
//     query getNetworkBuilds($buildId: ID!) {
//         networkBuilds(buildId: $buildId) {
//             id
//             state
//             arrayIndex
//             buildIndex
//             filename
//             size
//             byteSize
//             fileHash
//             variationName
//             buildName
//             variation
//             customPath
//             localization
//             command
//             network
//             duration
//             includedSets
//             excludedSets
//             errors
//             warnings
//             analyticsEnabled
//             buildToolWarnings
//             analyticsBatchId
//             analyticsBuildId
//             analyticsVersion
//         }
//     }
// `;

export const GET_NETWORK_BUILDS_WITH_FILTER = gql`
    query networkBuildsWithFilter(
        $buildId: ID!
        $sortBy: String!
        $searchValue: String
        $searchData: String
    ) {
        networkBuildsWithFilter(
            buildId: $buildId
            sortBy: $sortBy
            searchValue: $searchValue
            searchData: $searchData
        ) {
            id
            state
            arrayIndex
            buildIndex
            filename
            size
            byteSize
            fileHash
            variationName
            buildName
            variation
            customPath
            localization
            command
            network
            duration
            includedSets
            excludedSets
            errors
            warnings
            analyticsEnabled
            buildToolWarnings
            analyticsBatchId
            analyticsBuildId
            analyticsVersion
            networkTest {
                id
                state
                isAutomatic
                report
                dateUpdated
            }
            userQueryBuildAnalytic {
                id
                impressions
                gameplay
                endScreen
            }
            dailyBuildAnalytic {
                id
                impressions
                gameplay
                endScreen
            }
        }
    }
`;

export const GET_ANALYTICS_NETWORK_BUILDS = gql`
    query analyticsNetworkBuilds($buildId: ID!) {
        analyticsNetworkBuilds(buildId: $buildId) {
            id
            build {
                id
                revision {
                    id
                    project {
                        id
                        product {
                            id
                        }
                    }
                }
            }
            state
            filename
            buildName
            variation
            variationName
            size
            duration
            network
            analyticsEnabled
            analyticsBatchId
            analyticsBuildId
            analyticsVersion
        }
    }
`;

export const GET_ANALYTICS_NETWORK_BUILDS_WITH_ANALYTICS_IDS = gql`
    query analyticsNetworkBuildsWithAnalyticsIds(
        $analyticsBuildIds: [String]!
    ) {
        analyticsNetworkBuildsWithAnalyticsIds(
            analyticsBuildIds: $analyticsBuildIds
        ) {
            id
            build {
                id
                reportData
                revision {
                    id
                    name
                    project {
                        id
                        name
                        product {
                            id
                            name
                        }
                    }
                }
            }
            state
            filename
            buildName
            variation
            variationName
            size
            duration
            network
            analyticsEnabled
            analyticsBatchId
            analyticsBuildId
            analyticsVersion
        }
    }
`;

///////////////////
// Network Tests //
///////////////////
export const GET_NETWORK_TEST = gql`
    query ($networkBuildId: ID!) {
        networkTest(networkBuildId: $networkBuildId) {
            id
            network
            report
            state
        }
    }
`;

///////////////////
// Notifications //
///////////////////
export const GET_NOTIFICATIONS = gql`
    query getNotifications {
        getNotifications {
            id
            title
            message
            dateCreated
            dateUpdated
            isNew
        }
    }
`;

///////////////
// Analytics //
///////////////
export const GET_USER_ANALYTICS_QUERY_BUILD_ANALYTICS = gql`
    query getDailyBuildAnalytics($id: ID!) {
        getUserQueryBuildAnalytics(id: $id) {
            id
            adBuildId
            impressions
            gameplay
            endScreen
            isSaved
            dateCreated
        }
    }
`;

export const GET_USER_ANALYTICS_QUERY_ANALYTIC = gql`
    query getUserQueryAnalytic($referenceId: ID!, $reference: String!) {
        getUserQueryAnalytic(referenceId: $referenceId, reference: $reference) {
            id
            impressions
            gameplay
            endScreen
            isSaved
            dateCreated
        }
    }
`;

export const GET_USER_ANALYTICS_QUERY_BUILD_ANALYTIC = gql`
    query getUserQueryBuildAnalytic($id: ID!) {
        getUserQueryBuildAnalytic(id: $id) {
            id
            impressions
            gameplay
            endScreen
            isSaved
            dateCreated
        }
    }
`;

export const GET_ANALYTICS_ENABLED_BUILDS = gql`
    query getAnalyticsEnabledBuilds($selection: [Int]!, $revisionId: ID!) {
        getAnalyticsEnabledBuilds(
            selection: $selection
            revisionId: $revisionId
        ) {
            id
            analyticsSchema
            networkBuilds {
                id
                analyticsBuildId
                analyticsBatchId
                filename
            }
            revision {
                id
                name
                project {
                    id
                    product {
                        id
                        organization {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_BATCH_BUILD_BY_ANALYTICS_BUILD = gql`
    query getAnalyticsEnabledBuild($buildId: String!) {
        analyticsEnabledBuild(buildId: $buildId) {
            id
            analyticsSchema
            revision {
                id
                name
                project {
                    id
                    product {
                        id
                        organization {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LEGACY_BATCH_BUILD_BY_ANALYTICS_BUILD = gql`
    query getLegacyBuildSet($buildId: String!) {
        legacyBuildSet(buildId: $buildId) {
            id
            projectName
            gitProject
            gitBranch
            gitCommit
            batchId
            version
            networks
            builds {
                id
                buildId
                filename
            }
        }
    }
`;

export const GET_LEGACY_ANALYTICS_ENABLED_BUILD_SETS = gql`
    query getLegacyAnalyticsEnabledBuildSets {
        getLegacyAnalyticsEnabledBuildSets {
            id
            projectName
            gitProject
            gitBranch
            gitCommit
            batchId
            version
            networks
            builds {
                id
                buildId
                filename
            }
        }
    }
`;

export const GET_ANALYTICS_TAGS = gql`
    query getAnalyticsTags {
        organizations {
            id
            name
        }
        products {
            id
            name
        }
        projects {
            id
            name
        }
        revisions {
            id
            name
        }
    }
`;

export const GET_SAVED_ANALYTICS_QUERIES = gql`
    query getUserAnalyticsQueries {
        getUserAnalyticsQueries {
            id
            name
            description
            type
            config
            queryTask {
                type
                subQueries {
                    key
                    result
                }
            }
        }
    }
`;

export const GET_ANALYTICS_EVENTS = gql`
    query events {
        events {
            id
            eventName
            eventData
            comment
            frozen
        }
    }
`;

export const GET_ANALYTICS_EVENTS_BY_ORGANIZATION = gql`
    query getEventsByOrgId($organizationId: ID!) {
        getEventsByOrgId(organizationId: $organizationId) {
            id
            eventName
            eventData
            comment
            frozen
            organization {
                id
            }
        }
    }
`;

export const GET_ANALYTICS_SCHEMA = gql`
    query getAnalyticsSchema(
        $analyticsProducts: [ID]
        $analyticsProjects: [ID]
        $analyticsRevisions: [ID]
        $analyticsBuilds: [ID]
        $analyticsNetworkBuilds: [ID]
        $apiVersion: String!
    ) {
        getAnalyticsSchema(
            analyticsProducts: $analyticsProducts
            analyticsProjects: $analyticsProjects
            analyticsRevisions: $analyticsRevisions
            analyticsBuilds: $analyticsBuilds
            analyticsNetworkBuilds: $analyticsNetworkBuilds
            apiVersion: $apiVersion
        ) {
            success
            schema
            isComplete
        }
    }
`;

export const GET_ANALYTICS_SCHEMA_BY_ID = gql`
    query getAnalyticsSchemaById($buildId: ID!) {
        getAnalyticsSchemaById(buildId: $buildId) {
            success
            schema
        }
    }
`;

export const GET_DAILY_ANALYTIC = gql`
    query getDailyAnalytic($referenceId: ID!, $reference: String!) {
        getDailyAnalytic(referenceId: $referenceId, reference: $reference) {
            id
            product {
                id
            }
            project {
                id
            }
            revision {
                id
            }
            build {
                id
            }
            impressions
            gameplay
            endScreen
        }
    }
`;

export const GET_DAILY_ANALYTICS = gql`
    query getDailyAnalytics($reference: String!) {
        getDailyAnalytics(reference: $reference) {
            id
            product {
                id
            }
            project {
                id
            }
            revision {
                id
            }
            build {
                id
            }
            impressions
            gameplay
            endScreen
        }
    }
`;

export const GET_DAILY_BUILD_ANALYTICS = gql`
    query getDailyBuildAnalytics($id: ID!) {
        getDailyBuildAnalytics(id: $id) {
            id
            adBuildId
            impressions
            gameplay
            endScreen
        }
    }
`;

export const GET_DAILY_BUILD_ANALYTIC = gql`
    query getDailyBuildAnalytic($id: ID!) {
        getDailyBuildAnalytic(id: $id) {
            id
            adBuildId
            impressions
            gameplay
            endScreen
        }
    }
`;

export const GET_ANALYTICS_BUILD = gql`
    query getAnalyticsBuild($buildId: ID!) {
        analyticsBuild(buildId: $buildId) {
            id
            buildId
            batchId
            network
            tag
            filename
            analyticsSchema
        }
    }
`;

export const GET_ANALYTICS_FUNNEL_ENABLED = gql`
    query funnelEnabled(
        $productId: ID
        $projectId: ID
        $revisionId: ID
        $buildId: ID
    ) {
        funnelEnabled(
            productId: $productId
            projectId: $projectId
            revisionId: $revisionId
            buildId: $buildId
        )
    }
`;

export const GET_DAILY_ANALYTICS_LIST = gql`
    query getDailyAnalyticsList($input: DailyAnalyticsListInput!) {
        getDailyAnalyticsList(input: $input) {
            product {
                id
                name
            }
            project {
                id
                name
            }
            revision {
                id
                name
            }
            build {
                id
            }
            allGameplay
            allEndScreen
            allImpressions
            ctr
        }
    }
`;

////////////////////////
// Dev Api Key Status //
////////////////////////
export const QUERY_DEV_KEY_STATUS = gql`
    query getDevApiKeyStatus {
        getDevApiKeyStatus {
            status
        }
    }
`;

///////////////////////////
// Create Project Config //
///////////////////////////
export const GET_CREATE_PROJECT_CONFIG = gql`
    query getCreateProjectConfig {
        getCreateProjectConfig {
            gitModules
            baseProjects
            techStack
        }
    }
`;

////////////////////////
// Gitlab Slug Status //
////////////////////////
export const GET_GITLAB_SLUG_STATUS = gql`
    query getGitlabSlugStatus($slug: String!) {
        getGitlabSlugStatus(slug: $slug) {
            success
            message
        }
    }
`;

////////////////////////
// Current TempBuilds //
////////////////////////
export const GET_CURRENT_BUILDS = gql`
    query getTempBuilds($revisionId: ID!) {
        currentTempBuilds(revisionId: $revisionId) {
            taskId
        }
    }
`;

//////////
// Link //
//////////
export const GET_TEMP_LINK = gql`
    query generateLink($key: String!, $productId: ID!, $localization: String) {
        generateLink(
            key: $key
            productId: $productId
            localization: $localization
        ) {
            link
        }
    }
`;

export const GET_TEMP_LINK_WITH_KEY = gql`
    query generateLink($key: String!, $bucket: String) {
        generateLink(key: $key, bucket: $bucket) {
            link
            key
        }
    }
`;

export const GET_PUBLIC_LINK = gql`
    query generatePublicLink($buildId: ID!, $fileName: String!) {
        generatePublicLink(buildId: $buildId, fileName: $fileName) {
            link
        }
    }
`;

//////////////////
// File Uploads //
//////////////////
export const GET_FILE_UPLOADS = gql`
    query getFileUploads($productId: ID!) {
        fileUploads(productId: $productId) {
            id
            name
            key
        }
    }
`;

//////////////////
// Example Name //
//////////////////
export const GET_EXAMPLE_NAME = gql`
    query getExampleName($nameTemplate: String!, $revisionId: ID!) {
        getExampleName(nameTemplate: $nameTemplate, revisionId: $revisionId)
    }
`;

export const GET_EXAMPLE_COMMAND = gql`
    query getExampleCommand($revisionId: ID!, $targetNetwork: String) {
        getExampleCommand(
            revisionId: $revisionId
            targetNetwork: $targetNetwork
        ) {
            name
            command
            include
        }
    }
`;

//////////////////
// Localization //
//////////////////
export const GET_LOCALIZATION_DATA = gql`
    query localizationData($revisionConfigId: ID!, $localization: String!) {
        getLocalization(
            revisionConfigId: $revisionConfigId
            localization: $localization
        ) {
            localizationKey
            localizationJson
        }
    }
`;

export const GET_LOCALIZATIONS_JSON = gql`
    query getLocalizationsJson($revisionId: ID!, $localizationData: String) {
        getLocalizationsJson(
            revisionId: $revisionId
            localizationData: $localizationData
        ) {
            localizationKey
            localizationJson
        }
    }
`;

export const GET_VALID_LOCALIZATION_TAGS = gql`
    query getValidLocalizationTags {
        getValidLocalizationTags
    }
`;

///////////////////
// Release Notes //
///////////////////
export const FETCH_RELEASE_NOTES = gql`
    query getReleaseNotes($revisionId: ID!) {
        getReleaseNotes(revisionId: $revisionId) {
            releaseNotes
        }
    }
`;

///////////////////
// Pipeline Logs //
///////////////////
export const GET_PIPELINE_LOGS = gql`
    query getPipelineLogs($buildId: ID!) {
        getPipelineLogs(buildId: $buildId) {
            startJob
            cleanJob
            notifyJob
            buildJobs
        }
    }
`;

////////////
// Aditor //
////////////
export const ADITOR_INIT_QUERY = gql`
    query aditorInitQuery($revisionId: ID!, $localizationData: String) {
        aditorTemplates
        aditorLoadScene(revisionId: $revisionId) {
            data
        }
        getLocalizationsJson(
            revisionId: $revisionId
            localizationData: $localizationData
        ) {
            localizationKey
            localizationJson
        }
    }
`;

export const ADITOR_GET_TEMPLATES = gql`
    query aditorTemplates {
        aditorTemplates
    }
`;

export const ADITOR_GET_TEMPLATE_URLS = gql`
    query aditorTemplateUrls {
        aditorTemplateUrls {
            template
            url
        }
    }
`;

/////////////
// Mutator //
/////////////
export const MUTATOR_QUERY = gql`
    query mutatorConfig($revisionId: ID!) {
        mutatorConfig(revisionId: $revisionId) {
            mutatorConfig
            mutatorKeyPrefix
        }
    }
`;

export const MUTATOR_ASSETBUNDLES = gql`
    query mutatorAssetbundles($revisionId: ID!) {
        mutatorAssetbundles(revisionId: $revisionId) {
            name
            path
            url
        }
    }
`;

///////////////////////////
// Platform Config //
///////////////////////////
export const GET_PLATFORM_CONFIG = gql`
    query getPlatformConfig {
        getPlatformConfig {
            id
            useBuildRunner
            supportedNetworks
            buildRunnerNetworks
            analyticsNetworks
            qrNetworks
            networkTestingNetworks
            networkTestingMinimumState
            analyticsApiVersion
        }
    }
`;

////////////////
// Invitation //
////////////////
export const GET_PENDING_USER_INVITATIONS = gql`
    query getInvitations($pending: Boolean) {
        getInvitations(pending: $pending) {
            id
            email
            token
            dateExpired
            user {
                id
                email
                displayName
            }
        }
    }
`;

import React, { FC, useEffect, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    CreateBatchBuildData,
    LegacyLocalization,
    Localization,
    MutatorQueryData,
    MutatorVariables,
    ReleaseType,
    Revision
} from "../../models/types";
import { CREATE_BUILD } from "../../graphql/mutations";
import { Button, Card, Container, Collapse } from "react-bootstrap";
import { History } from "./History";
import { GET_RESULTS, MUTATOR_QUERY } from "../../graphql/queries";
import { useLocalBuildsState, useLocalState } from "../../graphql/hooks";
import {
    checkBuildSelection,
    updateEditPanelState,
    updateNotificationState,
    validateBuildConfig
} from "../../common/Helpers";
import { SimpleToast } from "../simple/SimpleToast";
import { initialEditPanelState } from "../../graphql/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

interface Props {
    revision: Revision;
}

const EditPanelBuildsImplementation: FC<Props> = ({ revision }) => {
    const client = useApolloClient();
    const { developerMode } = useLocalState();
    const buildsState = useLocalBuildsState();
    const [canBuild, setBuildStatus] = useState(true);
    const [hasErrors, updateHasErrors] = useState(false);
    const [createBuild] = useMutation<CreateBatchBuildData, any>(CREATE_BUILD);
    const [showWarning, setShowWarning] = useState(false);
    const [warnings, updateWarnings] = useState<
        { base?: string; network?: string }[]
    >([]);
    const [showBuildWarning, setShowBuildWarning] = useState(false);

    const { loading, data: { mutatorConfig } = {} } = useQuery<
        MutatorQueryData,
        MutatorVariables
    >(MUTATOR_QUERY, {
        variables: {
            revisionId: Number(revision.id)
        }
    });

    useEffect(() => {
        const checkLegacyLocalization = () => {
            if (revision.buildData.useLegacyLocalizations) {
                const localizations = revision.buildData.legacyLocalizations;
                const hasSelection =
                    localizations &&
                    localizations.some(
                        (element: LegacyLocalization) =>
                            element.include && element.localization
                    );
                if (revision.buildData.pathTemplate.includes("localization")) {
                    if (!hasSelection) {
                        return [
                            true,
                            "Legacy localization enabled and path template contains localization, but no legacy localization selected!"
                        ];
                    }
                } else {
                    const nameTemplate =
                        revision.buildData.nameTemplate.toLowerCase();
                    if (
                        !hasSelection &&
                        nameTemplate.includes("#{localization}")
                    ) {
                        return [
                            true,
                            "Legacy localization enabled and name template contains localization, but no legacy localization selected!"
                        ];
                    }
                    if (
                        hasSelection &&
                        !nameTemplate.includes("#{localization}")
                    ) {
                        return [
                            true,
                            "Legacy localization enabled and path template or name template does NOT contain localization template string!"
                        ];
                    }
                }
            }
            return [false, ""];
        };

        const defaultLocalizationMissing =
            !revision.buildData.localizations?.some(
                (item: Localization) => item.default
            ) && !revision.buildData?.useLegacyLocalizations;
        const pathTemplateCheck =
            revision.buildData.pathTemplate === undefined ||
            revision.buildData.pathTemplate === "";
        const mutatorConfigCheck =
            revision.buildData.useMutator && !mutatorConfig?.mutatorConfig;

        const legacyLocalizationCheck = checkLegacyLocalization();
        const compressionCheck = !revision.buildData.gzip;
        const newState = {
            ...initialEditPanelState,
            legacyLocalizationCheck: legacyLocalizationCheck[0] as boolean,
            legacyLocalizationErrorString: legacyLocalizationCheck[1] as string,
            pathTemplateCheck: pathTemplateCheck,
            mutatorConfigCheck: mutatorConfigCheck,
            defaultLocalizationMissing: defaultLocalizationMissing,
            compressionCheck: compressionCheck
        };
        updateEditPanelState(client, newState);
        updateHasErrors(
            defaultLocalizationMissing ||
                pathTemplateCheck ||
                mutatorConfigCheck ||
                (legacyLocalizationCheck[0] as boolean)
        );

        if (!developerMode) {
            const warnings = validateBuildConfig(revision.buildData);
            setShowWarning(warnings.length > 0);
            updateWarnings(warnings);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revision.buildData, developerMode, mutatorConfig]);

    useEffect(() => {
        let timer: any | undefined = undefined;
        if (!canBuild) {
            timer = setTimeout(() => {
                setBuildStatus(true);
            }, 2000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [canBuild]);

    const build = async () => {
        if (buildsState.buildJobsData === undefined || !canBuild) {
            return;
        }
        setBuildStatus(false);
        const variables = checkBuildSelection(buildsState.buildJobsData)
            ? { revisionId: revision.id }
            : {
                  revisionId: revision.id,
                  buildData: JSON.stringify(buildsState.buildJobsData)
              };

        try {
            const response = await createBuild({
                variables: variables,
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: {
                            revisionId: revision.id,
                            clientTest:
                                !developerMode &&
                                revision.releaseType === ReleaseType.Development
                        }
                    }
                ]
            });
            console.log("createBuild response: ", response);
            updateNotificationState(client, false, true, {
                success: true,
                header: "Build Status",
                message: `Build started successfully! (id: ${response?.data?.createBuild.id})`,
                delay: 3000
            });
        } catch (error) {
            updateNotificationState(client, false, true, {
                success: false,
                header: "Build Status",
                // @ts-ignore
                message: error.message,
                delay: 3000
            });
        }
    };

    const buildCount = buildsState.buildJobsData
        ? buildsState.buildJobsData.reduce(
              (accumulator, current) =>
                  current.include ? ++accumulator : accumulator,
              0
          )
        : 0;
    const allowBuild = canBuild && !hasErrors && buildCount > 0;

    const getWarnings = () => {
        return warnings.map((warning, index) => {
            if (!warning.base || !warning.network) {
                return (
                    <div key={index}>
                        Configuration error: missing base versions or missing
                        networks
                    </div>
                );
            }
            return (
                <div key={index}>
                    <b>{warning.base}</b> is not compatible with network{" "}
                    <b>{warning.network}</b> size targets
                </div>
            );
        });
    };

    return (
        <>
            <Container className="mb-2 p-0">
                <Card className="mb-4">
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-1">
                            Generate Builds
                        </h5>
                        <div className="float-end ms-auto">
                            {showWarning && (
                                <FontAwesomeIcon
                                    title="Show warnings"
                                    icon={faTriangleExclamation}
                                    className="text-warning me-3 mouseHover"
                                    onClick={() =>
                                        setShowBuildWarning(!showBuildWarning)
                                    }
                                />
                            )}
                            <Button
                                title={cx({
                                    "Generate builds": allowBuild
                                })}
                                className={cx("pb-1 pt-1", {
                                    "btn-success": allowBuild,
                                    "btn-secondary": !allowBuild
                                })}
                                disabled={!allowBuild}
                                onClick={() => {
                                    if (allowBuild) {
                                        build();
                                    }
                                }}
                            >
                                Build ({buildCount})
                            </Button>
                        </div>
                        <SimpleToast />
                    </Card.Header>
                    <Collapse in={showBuildWarning && warnings.length > 0}>
                        <div>
                            <Card.Footer className="text-inverse-50 no-border bread-text">
                                {warnings.length > 0 ? (
                                    getWarnings()
                                ) : (
                                    <>
                                        Warning generated texts here according
                                        to any and all problems in build setup.
                                    </>
                                )}
                            </Card.Footer>
                        </div>
                    </Collapse>
                </Card>
                <History isFullHistory={false} />
            </Container>
        </>
    );
};

export const EditPanelBuilds = React.memo(EditPanelBuildsImplementation);

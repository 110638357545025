import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col } from "react-bootstrap";
import { ADITOR_GET_TEMPLATES } from "../../../graphql/queries";
import {
    AditorTemplateData,
    AditorTemplateMutationData,
    AditorTemplateMutationVariables
} from "../../../models/aditor";
import { AditorTemplateUploadModal } from "../../modals/admin/AditorTemplateUploadModal";
import { ADITOR_TEMPLATE_REMOVE } from "../../../graphql/mutations";

export const AditorTools = () => {
    const client = useApolloClient();
    const [showModal, updateShowModal] = useState(false);
    const { data: { aditorTemplates } = {} } =
        useQuery<AditorTemplateData>(ADITOR_GET_TEMPLATES);
    const [aditorTemplateRemove] = useMutation<
        AditorTemplateMutationData,
        AditorTemplateMutationVariables
    >(ADITOR_TEMPLATE_REMOVE);

    const alternatives = aditorTemplates?.map(
        (template: string, index: number) => (
            <Card key={index} className="col-12 text-inverse m-2">
                <Card.Body className="d-flex flex-row justify-content-between align-items-center">
                    <div>{template}</div>
                    <Button
                        variant="danger"
                        onClick={() => {
                            aditorTemplateRemove({
                                variables: {
                                    templateKey: template
                                }
                            })
                                .then(_ => {
                                    client.refetchQueries({
                                        include: [
                                            "aditorTemplates",
                                            "aditorTemplateUrls"
                                        ]
                                    });
                                })
                                .catch(error => {
                                    console.log(
                                        "[DEBUG] aditorTemplateRemove failed with error ",
                                        error
                                    );
                                });
                        }}
                    >
                        Remove
                    </Button>
                </Card.Body>
            </Card>
        )
    );

    return (
        <>
            <h2 className="text-inverse m-2">Aditor Admin Tools</h2>
            <Card className="mt-3 mb-3">
                <Card.Header>
                    <h5 className="text-inverse">Templates</h5>
                </Card.Header>
                <Card.Body>
                    {alternatives}
                    <Col className="col-12 text-inverse">
                        <Button
                            onClick={() => {
                                updateShowModal(true);
                            }}
                        >
                            Upload New
                        </Button>
                    </Col>
                </Card.Body>
            </Card>
            <AditorTemplateUploadModal
                show={showModal}
                onClose={() => updateShowModal(!showModal)}
            />
        </>
    );
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import {
    useBlocker,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import { BackButton } from "../../simple/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import {
    AditorCreateInput,
    AditorCreateVariables,
    AditorInitQueryData,
    AditorMessage,
    AditorSceneVariables,
    AditorUpdateData,
    AditorUpdateInput,
    AditorUpdateVariables,
    CreateAditorSceneData,
    CreateAditorSceneResult,
    DeleteAditorSceneData,
    UpdateAditorSceneData
} from "../../../models/aditor";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    ADITOR_CREATE_SCENE,
    ADITOR_DELETE_SCENE,
    ADITOR_UPDATE_SCENE
} from "../../../graphql/mutations";
import { ADITOR_INIT_QUERY, GET_REVISION } from "../../../graphql/queries";
import { AditorOptimizingModal } from "../../modals/aditor/AditorOptimizingModal";
import { useLocalState, useRevisionStandby } from "../../../graphql/hooks";
import { getSceneAsBase64 } from "../../../common/AditorHelpers";
import { AlertModal } from "../../modals/AlertModal";
import { RevisionData, RevisionVariables } from "../../../models/types";
import styles from "../../panels/EditPanel.module.scss";

const AditorViewImplementation = () => {
    const { revisionId } = useParams<{
        revisionId: string;
    }>();
    const client = useApolloClient();
    const revision = useRevisionStandby(Number(revisionId));
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const { developerMode } = useLocalState();
    const [aditorReady, updateAditorReady] = useState(false);
    const [htmlContent, setHtmlContent] = useState<string>("");
    const [showOptimizingModal, updateShowOptimizingModal] = useState(false);
    const [fullScreen, updateFullScreen] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);

    const aditorReference = useRef<any>(null);
    const fullScreenReference = useRef(false);

    const buildDataLocalization =
        revision && revision.buildData && revision.buildData.localizations
            ? revision.buildData.localizations
            : undefined;

    const {
        loading: aditorInitLoading,
        error: aditorInitError,
        data
    } = useQuery<AditorInitQueryData, AditorSceneVariables>(ADITOR_INIT_QUERY, {
        variables: {
            revisionId: Number(revisionId),
            localizationData: JSON.stringify(buildDataLocalization)
        },
        fetchPolicy: "network-only"
    });

    const [createSceneMutation] = useMutation<
        CreateAditorSceneData,
        AditorCreateVariables
    >(ADITOR_CREATE_SCENE);

    const [updateSceneMutation] = useMutation<
        UpdateAditorSceneData,
        AditorUpdateVariables
    >(ADITOR_UPDATE_SCENE);

    const [deleteSceneMutation] = useMutation<
        DeleteAditorSceneData,
        AditorSceneVariables
    >(ADITOR_DELETE_SCENE);

    const handleKeydown = useCallback((event: any) => {
        if (fullScreenReference.current && event.key === "Escape") {
            toggleFullscreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aditorReady) {
            const iframe = aditorReference.current;
            if (!iframe) {
                console.log("[DEBUG] iframe not found stopping eventlisteners");
                return;
            }
            const iframeDocument = iframe.contentWindow.document;
            iframeDocument.addEventListener("keydown", (event: any) =>
                handleKeydown(event)
            );
            window.addEventListener("keydown", (event: any) =>
                handleKeydown(event)
            );
            return () => {
                iframeDocument.removeEventListener("keydown", handleKeydown);
                window.removeEventListener("keydown", handleKeydown);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aditorReady]);

    useEffect(() => {
        if (aditorReady && !aditorInitLoading && !aditorInitError && data) {
            const scopes = [developerMode ? "full" : "client"];
            if (data.aditorLoadScene && data.aditorLoadScene.data) {
                getSceneAsBase64(data.aditorLoadScene.data)
                    .then(result => {
                        const initMessage: AditorMessage = {
                            type: "init",
                            data: {
                                user: {
                                    scopes: scopes
                                },
                                revisionName: revision.name || "",
                                projectName: revision.project.name || "",
                                productName:
                                    revision.project.product?.name || "",
                                organizationName:
                                    revision.project.product?.organization
                                        .name || "",
                                data: result,
                                templates: data.aditorTemplates,
                                localizations: !revision.buildData
                                    .useLegacyLocalizations
                                    ? data.getLocalizationsJson
                                          ?.localizationJson
                                    : undefined
                            }
                        };
                        sendMessageToIframe(initMessage);
                        // if (result) {
                        //     updateLoadedScene(true);
                        // }
                    })
                    .catch(error => {
                        console.error("Error loading scene: ", error);
                    });
            } else {
                const initMessage: AditorMessage = {
                    type: "init",
                    data: {
                        user: {
                            scopes: scopes
                        },
                        revisionName: revision.name || "",
                        projectName: revision.project.name || "",
                        productName: revision.project.product?.name || "",
                        organizationName:
                            revision.project.product?.organization.name || "",
                        data: undefined,
                        templates: data.aditorTemplates,
                        localizations:
                            data.getLocalizationsJson?.localizationJson
                    }
                };
                sendMessageToIframe(initMessage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aditorInitLoading, aditorInitError, aditorReady]);

    useEffect(() => {
        const fetchHtmlFromS3 = async () => {
            try {
                // Official Aditor
                const response = await fetch(
                    "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor.html"
                );
                // Local test build
                // const response = await fetch(
                //     "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor_test.html"
                // );
                // Test index
                // const response = await fetch(
                //     "https://seepia-review.s3.eu-north-1.amazonaws.com/seepia/experimental/aditor/test_iframe.html",
                //     {
                //         headers: {
                //             "Cache-Control": "must-revalidate"
                //         }
                //     }
                // );
                if (!response.ok) {
                    throw new Error("Failed to fetch the HTML file from S3");
                }

                const htmlData = await response.text();
                setHtmlContent(htmlData);
            } catch (error) {
                console.error("Error fetching the file:", error);
            }
        };

        fetchHtmlFromS3();
    }, []);

    useEffect(() => {
        window.addEventListener("message", handleAditorMessages);
        return () => {
            window.removeEventListener("message", handleAditorMessages);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendCreatedSceneToIFrame = async (
        sceneData: CreateAditorSceneResult
    ) => {
        try {
            const base64String = await getSceneAsBase64(sceneData.data);
            sendMessageToIframe({
                type: "createdScene",
                data: base64String
            });
        } catch (error) {
            console.log("[DEBUG] sendCreatedSceneToIFrame with error ", error);
        }
    };

    const createScene = (template: string) => {
        const input: AditorCreateInput = {
            revisionId: Number(revisionId),
            template: template
        };
        createSceneMutation({
            variables: {
                input: input
            }
        })
            .then(result => {
                if (result.data) {
                    sendCreatedSceneToIFrame(result.data.aditorCreateScene);
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from createSceneMutation ",
                        result
                    );
                }
            })
            .catch(error =>
                console.log(
                    "[DEBUG] createSceneMutation failed with error ",
                    error
                )
            );
    };

    const deleteScene = () => {
        deleteSceneMutation({
            variables: {
                revisionId: Number(revisionId)
            }
        })
            .then(result => {
                console.log("[DEBUG] delete scene result ", result);
                if (result.data) {
                    sendMessageToIframe({
                        type: "deletedScene",
                        data: {
                            success: true
                        }
                    });
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from deleteSceneMutation ",
                        result
                    );
                    sendMessageToIframe({
                        type: "deletedScene",
                        data: {
                            success: false,
                            message:
                                "[DEBUG] unexpected result data from deleteSceneMutation " +
                                result
                        }
                    });
                }
            })
            .catch(error => {
                console.log(
                    "[DEBUG] deleteSceneMutation failed with error ",
                    error
                );
                sendMessageToIframe({
                    type: "deletedScene",
                    data: {
                        success: false,
                        message: error as string
                    }
                });
            });
    };

    const updateScene = (updateMessage: AditorUpdateData) => {
        const input: AditorUpdateInput = {
            revisionId: Number(revisionId),
            data: updateMessage.data,
            localizations: JSON.stringify(updateMessage.localizations)
        };
        updateSceneMutation({
            variables: {
                input: input
            }
        })
            .then(result => {
                let success = true;
                let message: string | undefined = undefined;
                if (result.data && result.data.aditorUpdateScene) {
                    sendMessageToIframe({
                        type: "updatedScene",
                        data: {
                            success: true
                        }
                    });
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from updateSceneMutation ",
                        result
                    );
                    success = false;
                    message = "unexpected result data from updateSceneMutation";
                }

                if (
                    result.data &&
                    result.data.updateAllLocalizations !== undefined
                ) {
                    if (result.data.updateAllLocalizations.version === null) {
                        console.log("[DEBUG] no localizations to update");
                    } else {
                        const currentCache = client.readQuery<
                            RevisionData,
                            RevisionVariables
                        >({
                            query: GET_REVISION,
                            variables: { revisionId: Number(revisionId) }
                        });
                        if (currentCache) {
                            const revision = { ...currentCache.revision };
                            if (typeof revision.buildData === "string") {
                                revision.buildData = JSON.parse(
                                    revision.buildData
                                );
                            }

                            const version =
                                result.data?.updateAllLocalizations.version;
                            const partialUpdate = JSON.parse(
                                JSON.stringify(
                                    result.data?.updateAllLocalizations
                                        .partialData
                                )
                            );
                            const updatedBuildData = {
                                ...revision.buildData,
                                version: version,
                                ...partialUpdate
                            };
                            client.writeQuery<RevisionData, RevisionVariables>({
                                query: GET_REVISION,
                                data: {
                                    revision: {
                                        ...revision,
                                        buildData: updatedBuildData
                                    }
                                },
                                variables: { revisionId: Number(revisionId) }
                            });
                        }
                    }
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from updateSceneMutation ",
                        result
                    );
                    success = false;
                    message = "unexpected result data from updateSceneMutation";
                }
                sendMessageToIframe({
                    type: "updatedScene",
                    data: {
                        success: success,
                        message: message
                    }
                });
            })
            .catch(error => {
                console.log(
                    "[DEBUG] updateSceneMutation failed with error ",
                    error
                );
                sendMessageToIframe({
                    type: "updatedScene",
                    data: {
                        success: false,
                        message: error as string
                    }
                });
            });
    };

    const toggleFullscreen = (newState: boolean) => {
        updateFullScreen(newState);
        fullScreenReference.current = newState;
        sendMessageToIframe({
            type: "fullscreen",
            data: newState
        });
    };

    const handleAditorMessages = (event: MessageEvent) => {
        if (event.source !== aditorReference.current?.contentWindow) {
            return;
        }
        // console.log("[DEBUG] handleAditorMessages event: ", event);
        try {
            const messageData = event.data;
            switch (messageData.type) {
                case "aditorReady": {
                    updateAditorReady(true);
                    break;
                }
                case "createScene": {
                    createScene(messageData.data);
                    break;
                }
                case "deleteScene": {
                    deleteScene();
                    break;
                }
                case "updateScene": {
                    updateScene(messageData.data);
                    break;
                }
                case "fullscreen": {
                    toggleFullscreen(messageData.data);
                    break;
                }
                case "dirty": {
                    if (typeof messageData.data !== "boolean") {
                        console.log(
                            "[DEBUG] messageData.data contained wrong value type ",
                            messageData.data
                        );
                        break;
                    }
                    setIsBlocking(messageData.data);
                    break;
                }
                default: {
                    console.log(
                        "[DEBUG] message type: ",
                        messageData.type,
                        " not implemented yet"
                    );
                    break;
                }
            }
        } catch (error) {
            console.error(
                "[ERROR] handleAditorMessages failed with error: ",
                error
            );
        }
    };

    const sendMessageToIframe = (message: AditorMessage) => {
        if (aditorReference) {
            // console.log("[DEBUG] sent message ", message);
            aditorReference.current?.contentWindow?.postMessage(message, "*");
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isBlocking && currentLocation.pathname !== nextLocation.pathname
    );

    return (
        <>
            <div className={fullScreen ? "aditorViewFull" : "aditorViewSmall"}>
                {!fullScreen ? (
                    <div className="p-1 border-bottom border-inverse-25 d-flex">
                        <div className={styles.backButton}>
                            <BackButton
                                hasHistory={
                                    !(location.key && navigationType === "POP")
                                }
                                goBack={() => navigate(-1)}
                                size={"lg"}
                            />
                        </div>
                        <div className="float-end ms-auto pe-3 pt-3">
                            <Button
                                className={styles.maximizeButton}
                                onClick={() => toggleFullscreen(true)}
                                title="Aditor Fullscreen mode"
                            >
                                <FontAwesomeIcon
                                    size="1x"
                                    icon={faUpRightAndDownLeftFromCenter}
                                />
                            </Button>
                        </div>
                    </div>
                ) : null}
                <iframe
                    id="aditor-iframe"
                    ref={aditorReference}
                    title="Aditor"
                    srcDoc={htmlContent}
                    width="100%"
                    height="100%"
                    className="border-0"
                />
            </div>
            {showOptimizingModal ? (
                <AditorOptimizingModal
                    revisionId={Number(revisionId)}
                    onClose={() => updateShowOptimizingModal(false)}
                />
            ) : null}
            {blocker.state === "blocked" ? (
                <AlertModal
                    header={<h5>Are you sure you want to leave?</h5>}
                    component="You have unsaved changes."
                    visible={() => blocker.reset()}
                    footer={
                        <Button
                            variant="warning"
                            className="m-2"
                            onClick={() => blocker.proceed()}
                        >
                            Leave
                        </Button>
                    }
                />
            ) : null}
        </>
    );
};

export const AditorView = React.memo(AditorViewImplementation);

import React, { useEffect } from "react";
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    from,
    InMemoryCache,
    split
} from "@apollo/client";
import {
    AnalyticsState,
    BuildsState,
    EditPanelState,
    MutatorState,
    NotificationState,
    PaginatorState,
    SortingState,
    State
} from "../models/common";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { WebSocketLink } from "@apollo/client/link/ws";
import {
    getDefaultFunnelTimestamps,
    getDefaultTimestamps
} from "../common/AnalyticsHelpers";
import {
    AnalyticsLoadingState,
    ProjectEnvironment,
    SortingMode
} from "../models/types";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { getEnvironment } from "../common/Helpers";

const { REACT_APP_URL, REACT_APP_WS_API_URL } = process.env;

export const initialLocalState: State = {
    initialized: false,
    buildNotifications: [],
    developerMode: true,
    isDeveloper: true,
    useDarkMode: false,
    showArchived: false,
    redirect: undefined,
    productTier: new Set<number>([]),
    projectTier: new Set<number>([]),
    searchTerm: undefined,
    id: "1",
    __typename: "state"
};

export const initialLocalAnalyticsState: AnalyticsState = {
    analyticsEnabled: false,
    analyticsSelectedTab: "funnels",
    funnelApiVersion: "1.0.0",
    analyticsFunnelSelectionMode: false,
    analyticsProducts: [],
    analyticsProjects: [],
    analyticsRevisions: [],
    analyticsBuilds: [],
    analyticsNetworkBuilds: [],
    analyticsTask: undefined,
    analyticsLoadingState: AnalyticsLoadingState.Ready,
    allCtrAnalyticsResults: [],
    funnelAnalyticsResults: [],
    funnelEvents: [
        { eventName: "", eventData: [] },
        { eventName: "", eventData: [] }
    ],
    analyticsTimestamps: getDefaultTimestamps(),
    funnelTimestamps: getDefaultFunnelTimestamps(),
    includeBuildPreview: false,
    includeBuildComparison: false,
    includeDayFunnel: false,
    clearCache: true,
    id: "1",
    __typename: "analyticsState"
};

export const initialLocalBuildsState: BuildsState = {
    buildJobsData: undefined,
    id: "1",
    __typename: "buildState"
};

export const initialNotificationState: NotificationState = {
    showLoading: false,
    showToast: false,
    toastData: {
        success: true,
        header: "Build Status",
        message: "Success",
        delay: 3000
    },
    id: "1",
    __typename: "buildState"
};

export const initialPaginatorState: PaginatorState = {
    currentPage: 1,
    id: "1",
    __typename: "paginatorState"
};

export const initialEditPanelState: EditPanelState = {
    legacyLocalizationCheck: false,
    legacyLocalizationErrorString: "",
    pathTemplateCheck: false,
    mutatorConfigCheck: false,
    defaultLocalizationMissing: false,
    compressionCheck: false,
    id: "1",
    __typename: "editPanelState"
};

export const initialSortingState: SortingState = {
    id: "1",
    sortingPriorities: [SortingMode.None, SortingMode.None, SortingMode.None],
    organizationSorting: false,
    __typename: "sortingState"
};

export const initialMutatorState: MutatorState = {
    id: "1",
    showMeta: false,
    __typename: "mutatorState"
};

const options = {
    typePolicies: {
        Query: {
            fields: {
                state: {
                    merge: true
                },
                projects: {
                    merge: false
                },
                revisions: {
                    merge: false
                },
                products: {
                    merge: false
                },
                emptyProjects: {
                    merge: false
                },
                pendingRevisions: {
                    merge: false
                },
                getUserAnalyticsQueries: {
                    merge: false
                },
                fileUploads: {
                    merge: false
                },
                getEventsByOrgId: {
                    merge: false
                },
                mutatorConfig: {
                    merge: false
                },
                getNotifications: {
                    merge: false
                }
            }
        }
    }
};

// @ts-ignore
export const AuthorizedApolloProvider = ({ children }) => {
    const {
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect
    } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            const returnPath = `${window.location.pathname}${window.location.search}`;
            const email = new URLSearchParams(location.search).get("email");
            loginWithRedirect({
                appState: {
                    returnTo: returnPath
                },
                authorizationParams: {
                    login_hint: email || undefined,
                    screen_hint: email ? "signup" : "login",
                    prompt: "select_account"
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated]);

    const httpLink = createHttpLink({
        uri: `${REACT_APP_URL}/api/`
    });

    const authLink = setContext(async () => {
        const token = await getAccessTokenSilently();
        // console.log("[DEBUG] `Bearer ${token}` ", `Bearer ${token}`);
        const headers = token
            ? {
                  Authorization: `Bearer ${token}`
              }
            : {};
        return {
            headers: headers
        };
    });

    const wsLink = new WebSocketLink(
        new SubscriptionClient(REACT_APP_WS_API_URL || "", {
            reconnect: true,
            lazy: true,
            connectionParams: async () => {
                const token = await getAccessTokenSilently();
                if (!token) {
                    return {};
                }
                const tokenString = `Bearer ${token}`;
                return {
                    token: tokenString
                };
            }
        })
    );

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        from([authLink, httpLink])
    );

    const apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(options)
    });

    if (getEnvironment() === ProjectEnvironment.Local) {
        // Adds messages only in a dev environment
        loadDevMessages();
        loadErrorMessages();
    }

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

import React from "react";
import { OverlayBadge } from "./OverlayBadge";
import { useEditPanelState } from "../../graphql/hooks";

export const CompressionCheckOverlayBadge = () => {
    const { compressionCheck } = useEditPanelState();

    if (!compressionCheck) {
        return null;
    }

    return (
        <OverlayBadge
            badgeVariant="warning"
            className="mt-2 p-1 me-2"
            badgeHeader="Compression not enabled"
            overlayText="Enable gzip in settings tab"
        />
    );
};
